import _reactMatterJsCjsProductionMin from "./react-matter-js.cjs.production.min.js";
export { _reactMatterJsCjsProductionMin as default };
export const __esModule = _reactMatterJsCjsProductionMin.__esModule,
      Circle = _reactMatterJsCjsProductionMin.Circle,
      Constraint = _reactMatterJsCjsProductionMin.Constraint,
      Engine = _reactMatterJsCjsProductionMin.Engine,
      Rectangle = _reactMatterJsCjsProductionMin.Rectangle,
      Render = _reactMatterJsCjsProductionMin.Render,
      RenderClones = _reactMatterJsCjsProductionMin.RenderClones,
      Scene = _reactMatterJsCjsProductionMin.Scene,
      Shape = _reactMatterJsCjsProductionMin.Shape,
      Vertices = _reactMatterJsCjsProductionMin.Vertices,
      Walls = _reactMatterJsCjsProductionMin.Walls,
      WindowScene = _reactMatterJsCjsProductionMin.WindowScene,
      relX = _reactMatterJsCjsProductionMin.relX,
      relY = _reactMatterJsCjsProductionMin.relY,
      useEngine = _reactMatterJsCjsProductionMin.useEngine;